import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Divider from '@material-ui/core/Divider';
import List from "@material-ui/core/List";

import ChatItem from "./item";
import ChatInput from "./input";

// Queries
import {
  MESSAGES_QUERY,
} from '../../graphql/queries';

// Mutations
import {
  MUTATION_ADD_MESSAGE,
} from '../../graphql/mutations';

const Chat = ({ productId }) => {
  const [messageText, setMessageText] = React.useState("");

  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch,
  } = useQuery(MESSAGES_QUERY, {
      variables: {
        productId,
      },
      fetchPolicy: 'cache-and-network',
  });
  const [
    createMessageMutation,
    { data: createMessageData, loading: createMessageLoading, error: createMessageError },
  ] = useMutation(MUTATION_ADD_MESSAGE, {
    refetchQueries: messagesRefetch(),
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      setMessageText("");
    },
  });

  return (
    <div>
      <h3>Produktkommentarer mellan Kvalitetsgranskning och Producent</h3>
      <Divider />
      <List>
          {messagesData?.messages?.map((message) => (
          <ChatItem message={message} />
          ))}
      </List>
      <ChatInput
          messageText={messageText}
          setMessageText={setMessageText}
          createMessageMutation={createMessageMutation}
          productId={productId}
      />
    </div>
  );
};

export default Chat;
