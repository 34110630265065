import React from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

interface IChatInputProps {
  messageText?: string;
  setMessageText?: React.Dispatch<React.SetStateAction<string>>;
  productId?: string;
  createMessageMutation?: (input: any) => void; // TODO any
}

const ChatInput = ({ messageText, setMessageText, createMessageMutation, productId }: IChatInputProps) => {
    return (
      <TextField
        multiline={true}
        rows={3}
        value={messageText}
        onChange={(e) => setMessageText && setMessageText(e.target.value)}
        placeholder="Lämna en kommentarer till person som Kvalitetsgranskar produkten"
        fullWidth={true}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ alignItems: "flex-start" }}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={!messageText || messageText.trim() === ""}
                onClick={(e) => {
                  e.preventDefault();
                  if (messageText?.trim() !== "") {
                    const input = {
                      variables: {
                        input: {
                          text: messageText,
                          productId,
                        },
                      },
                    };
                    createMessageMutation && createMessageMutation(input);
                  }
                }}
              >Send</Button>
            </InputAdornment>
          )
        }}
      />
    );
  };

  export default ChatInput;
