import { useMediaQuery } from '@material-ui/core';

const useViewport = () => {
  const checkingReady = useMediaQuery('(min-width:10px)');

  const smUp = useMediaQuery('(min-width:600px)');
  const mdUp = useMediaQuery('(min-width:900px)');
  const lgUp = useMediaQuery('(min-width:1200px)');

  return { ready: checkingReady, smUp, mdUp, lgUp };
};

export default useViewport;
