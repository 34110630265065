import React from 'react';
import moment from "moment";

import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

interface IMessage {
  isAdmin?: boolean;
  name?: string;
  text?: string;
  created_at?: string;
}

interface IChatItemProps {
  message?: IMessage;
}

const ChatItem = ({ message }: IChatItemProps) => {
  const style: React.CSSProperties = {};
  const textStyle: React.CSSProperties = {};
  let name: string | undefined = "";
  if (message?.isAdmin) {
    style.backgroundColor = "#56aba9";
    textStyle.fontSize = 12;
    name = "Foodla";
  } else {
    if (message?.name && message.name.trim() !== '') {
      name = message.name[0];
    } else {
      name = undefined;
    }
  }
  const getTime = () => {
    const created_at = message?.created_at;
    if (created_at) {
      return moment(parseInt(created_at, 0)).format("HH:mm, YYYY-MM-DD");
    }
    return "";
  };
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar variant="rounded" style={style}><b style={textStyle}>{name}</b></Avatar>
      </ListItemAvatar>
      <ListItemText
        secondary={message?.text}
        primary={`${message?.name} ${getTime()}`}
      />
    </ListItem>
  );
};

export default ChatItem;
